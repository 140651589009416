import React from "react"
import styled from "styled-components"
import { Actions, Container } from "../layoutComponents"
import { ButtonPrimary, ButtonSecondaryDark } from "../buttons"
import { StaticImage } from "gatsby-plugin-image"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr 1fr;
  align-items: center;
`

const Text = styled.div`
  max-width: 70ch;
  width: 100%;
  grid-column: 2 / span 1;
`

const ImgWrapper = styled.div`
  grid-column: 3 / -1;
`

export default function Hero2() {
  return (
    <>
      <Grid>
        <Text className="spacing">
          <p className="accent italics upper">
            taking you to new meditation heights
          </p>
          <h2 className="title">
            get transported to an all-encompassing relaxation experience for
            both body and mind
          </h2>
          <p>
            Elevate your massage with our unique sound vibration therapy and
            enjoy a truly relaxing experience on the drive out to our location
            through the beautiful rolling foothills.{" "}
          </p>
          <ButtonPrimary to="/book-an-appointment">
            book your massage
          </ButtonPrimary>
        </Text>
        <ImgWrapper>
          <StaticImage
            src="../../images/craniosacral-massage-calgary.jpg"
            alt="pacha mamma massage in Millarville, AB"
            className="stretch"
          />
        </ImgWrapper>
      </Grid>
    </>
  )
}
