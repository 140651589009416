import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, Container, Flex } from "./layoutComponents"
import Help from "./help"

const Text = styled.div``

export default function Benefits() {
  return (
    <Section>
      <Container>
        <Flex>
          <StaticImage
            src="../images/benefits-of-massage.jpg"
            alt="benefits of a massage calgary"
            className="stretch"
          />
          <Text>
            <p className="upper accent italics">
              balancing body, mind, and soul
            </p>
            <h2 className="title--bold">benefits of a massage</h2>
            <ul>
              <li>Get more restful sleep</li>
              <li>Eliminate pain</li>
              <li>Increased circulation</li>
              <li>Improved muscle function & immunity</li>
              <li>Decreased frequency and severity of tension headaches</li>
              <li>
                Improved posture and counteract muscle imbalances from sitting
                for hours at a computer
              </li>
              <li>Eliminate physical & emotional stress</li>
            </ul>
            <Help />
          </Text>
        </Flex>
      </Container>
    </Section>
  )
}
