import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, Container, Flex } from "./layoutComponents"
import { ButtonSecondary } from "./buttons"

export default function Bio() {
  return (
    <Section>
      <Container>
        <Flex>
          <StaticImage
            src="../images/registered-massage-therapist-calgary.jpg"
            alt="registered massage therapist calgary certificate - remedial massage therapist association"
            className="stretch"
          />
          <div className="spacing">
            <div>
              <p className="upper accent italics">
                dedicated to helping you heal
              </p>
              <h2 className="title--bold">
                personable, professional, registered massage therapists
              </h2>
            </div>
            <p>
              Massage is like the old saying, “An apple a day keeps the doctor
              away.” Regular massage helps the body to relax and improve
              circulation, which brings fresh blood to muscles. This effect can
              help the body to naturally heal itself. Having a massage at least
              once a month helps to keep the muscles tuned and ready for
              activity, as well as relax the mind. It’s the perfect addition to
              any self-care regime; like a mini-vacation each month!
            </p>
            <ButtonSecondary to="/contact">contact us</ButtonSecondary>
          </div>
        </Flex>
      </Container>
    </Section>
  )
}
